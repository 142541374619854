import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Uvixena Agence de marketing
			</title>
			<meta name={"description"} content={"Où chaque marque a une histoire à raconter"} />
			<meta property={"og:title"} content={"Uvixena Agence de marketing"} />
			<meta property={"og:description"} content={"Où chaque marque a une histoire à raconter"} />
			<meta property={"og:image"} content={"https://uvixena.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://uvixena.com/img/2464787.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uvixena.com/img/2464787.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uvixena.com/img/2464787.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uvixena.com/img/2464787.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uvixena.com/img/2464787.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uvixena.com/img/2464787.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uvixena.com/img/7.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="460px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Notre parcours
				</Text>
				<Text as="h1" font="--headline2" margin="0 0 12px 0">
				A propos de nous
				</Text>
				<Text font="--base">
				Embarquez dans l'histoire d'Uvixena, une histoire de passion, d'innovation et de poursuite incessante de l'excellence marketing. Nés du désir de créer des expériences numériques percutantes, nous sommes passés d'une humble équipe de passionnés du numérique à une agence de marketing à part entière. Notre parcours est alimenté par la conviction que chaque marque, quelle que soit sa taille, a une histoire unique qui attend d'être racontée. Malgré les hauts et les bas, nous sommes restés fidèles à notre mission : donner vie à ces histoires et les aider à trouver un écho auprès des publics du monde entier.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
			Ce qui nous distingue
			</Text>
			<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
			Au cœur d'Uvixena se trouvent nos collaborateurs - une équipe diversifiée de magiciens de la création, de stratèges en marketing et d'innovateurs technologiques. Chaque membre apporte sa touche unique, créant ainsi un creuset d'idées qui font avancer notre agence. Notre expertise s'étend à différents domaines, du SEO et du marketing de contenu aux médias sociaux et au développement de la marque, garantissant une approche à 360 degrés du marketing numérique. Nous sommes fiers de rester à la pointe de la technologie, en développant continuellement nos compétences pour répondre à l'évolution constante du paysage numérique.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Image
						src="https://uvixena.com/img/8.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Une approche innovante : Nous osons penser différemment, en explorant des voies créatives que d'autres pourraient négliger.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Des solutions sur mesure : Sachant qu'il n'existe pas deux marques identiques, nous adaptons nos stratégies à vos besoins et objectifs spécifiques.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Engagement en faveur de la croissance : Votre croissance est notre priorité. Nous ne nous contentons pas de gains rapides, mais nous visons un succès durable qui propulse votre marque vers l'avant.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Une collaboration transparente : Nous croyons en l'établissement de relations basées sur la confiance, la transparence et le respect mutuel, en vous tenant au courant à chaque étape du processus.
						</Text>
					</Box>
					<Button
						background="rgba(0, 119, 204, 0)"
						padding="0 0 0 0"
						color="--primary"
						font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
						href="/contact"
						type="link"
						text-decoration-line="initial"
					>
						Rendez nous visite 
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});